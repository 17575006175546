<template>

  <h2>Usage &amp; billing</h2>

  <section class="header">
    <div class="next-payment bordered">
      <h5>Your next payment</h5>
      <div class="helper-text">
        <strong>Next billing date:</strong>
        {{ nextBillingAt }}
      </div>
      <div class="breakdown">
        <div class="item">
          <span class="name">
            {{ nextUsageCycleUserPlan.name }} Plan
            <template v-if="nextUsageCyclePayPeriod">- {{ nextUsageCyclePayPeriod }}</template>
          </span>
          <span class="cost">{{ formatNumberAsUSD(nextUsageCyclePlanCost) }}</span>
        </div>
        <div class="item" v-if="isPPAEnabled || ppaCostEstimate">
          <span class="name">
            Pay per Action estimate
          </span>
          <span class="cost">{{ formatNumberAsUSD(ppaCostEstimate) }}</span>
        </div>
        <div class="item estimated-total">
          <span class="name">Estimated total*</span>
          <span class="cost">{{ formatNumberAsUSD(estimatedTotal) }}</span>
        </div>
        <div class="helper-text">
          *Taxes not included in estimate
        </div>
      </div>
    </div>
    <div class="links bordered">
      <h5>Billing &amp; payment history</h5>
      <template v-if="userPlan.stripeCustomerId">
        <button
          class="link"
          :disabled="isLoadingStripeBillingPortal"
          @click="openStripeBillingPortal('invoices')"
        >
          View or download invoices
        </button>
      </template>
      <template v-else>
        <Tooltip>
          <template #trigger>
            <button class="link disabled">View or download invoices</button>
          </template>
          You don't have any billing history to show.
        </Tooltip>
      </template>
      <template v-if="userPlan.stripeCustomerId">
        <button
          class="link"
          :disabled="isLoadingStripeBillingPortal"
          @click="openStripeBillingPortal('payment-methods')"
        >
        Change payment method
        </button>
      </template>
      <template v-else>
        <Tooltip>
          <template #trigger>
            <button class="link disabled">Change payment method</button>
          </template>
          You don't have any payment information to show.
        </Tooltip>
      </template>
      <router-link to="/account/plans">Learn about plans and pricing</router-link>
    </div>
  </section>

  <section class="current-plan">
    <h4>Current plan</h4>
    <PlanUsage />
  </section>

  <section class="add-ons">
    <h4>Add-ons</h4>
    <div class="bordered">
      <!-- @TODO: decide on this -->
      <!-- <template v-if="isPPAEnabled || ppaCostEstimate"> -->
      <template v-if="isPPAEnabled">
        <div class="mb-4 columned">
          <div>
            <h5>Pay per Action</h5>
            <div class="helper-text">
              <strong>Next billing date:</strong> {{ usageCycleEndsAt }}
            </div>
          </div>
          <div>
            <router-link
              class="button secondary small"
              to="/account/plans#pay-per-action"
            >
              Manage add-on
            </router-link>
            <div class="mt-2 helper-text" v-if="ppaActivatedAt">
              <strong>Activated:</strong> {{ ppaActivatedAt }}
            </div>
          </div>
        </div>
        <div>
          <div class="breakdown ppa-breakdown">
            <div class="item">
              <span class="name">Actions included with plan</span>
              <span class="cost">{{ formatNumber(userPlan.actionEventLimit) }}</span>
            </div>
            <div class="item">
              <span class="name">Actions over monthly limit</span>
              <span class="cost">{{ formatNumber(actionsOverPlanLimit) }}</span>
            </div>
            <div class="item">
              <span class="name">Price per Action</span>
              <span class="cost">$0.02</span>
            </div>
            <div class="item estimated-total">
              <span class="name">Estimated overage costs*</span>
              <span class="cost">{{ formatNumberAsUSD(ppaCostEstimate) }}</span>
            </div>
            <div class="helper-text">
              Taxes not included in estimate*
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mb-4 columned">
          <div>
            <span class="mb-2 badge font-normal subtle neutral">{{ isOnFreePlan ? 'For paid plans only' : 'Recommended' }}</span>
            <h5>Pay per Action</h5>
            <p class="text-lg text-gray-600">
              {{ isOnFreePlan ? 'Upgrade to Starter or Pro to get this add-on' : 'Keep the data flowing when you hit your plan limit' }}
            </p>
            <p>
              With Pay per Action enabled, your Patches stay on even when you hit your
              plan limit, ensuring you never miss crucial activity.
            </p>
            <p>
              Actions over your plan limit will be charged at a rate of $0.02 per Action.
            </p>
          </div>
          <div class="flex-shrink-0">
            <router-link
              v-if="isOnFreePlan"
              to="/account/plans"
              class="button small"
            >
              View plans
            </router-link>
            <router-link
              v-else
              class="button small"
              to="/account/plans#pay-per-action"
            >
              Learn more
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </section>

  <div class="questions">
    <p>
      Have questions about plans and pricing?
      <router-link to="/account/plans#faq">Lean about plans and billing</router-link>
    </p>
  </div>

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import PlanUsage from '@/components/etc/PlanUsage.vue'

  import useFilters from '@/composables/useFilters'
  import useStripeBillingPortal from '@/composables/useStripeBillingPortal'

  export default {
    components: {
      Tooltip,
      PlanUsage,
    },
    setup() {

      const { titleCase, formatNumber, formatNumberAsUSD } = useFilters()
      const { isLoadingStripeBillingPortal, openStripeBillingPortal } = useStripeBillingPortal()

      return {
        titleCase,
        formatNumber,
        formatNumberAsUSD,
        openStripeBillingPortal,
        isLoadingStripeBillingPortal,
      }

    },
    computed: {
      ...mapGetters('user', [
        'payPeriod',
        'isOnFreePlan',
        'isPPAEnabled',
        'ppaActivatedAt',
        'ppaCostEstimate',
        'usageCycleEndsAt',
        'actionsOverPlanLimit',
        'nextSubscriptionBillingAt',
        'shouldShowActionLimitWarnings',
      ]),
      ...mapState('app', ['stripePlansSlugMap']),
      ...mapState('user', [
        'user',
        'userPlan',
      ]),
      nextUsageCycleUserPlan() {
        if (
          this.isOnFreePlan
          || !this.userPlan.stripeSubscription
          || this.userPlan.stripeSubscription.cancelAtPeriodEnd // @TODO: unless... there's another subscription taking it's place????
        ) return this.stripePlansSlugMap.free
        return this.userPlan
      },
      nextUsageCyclePayPeriod() {
        if (
          this.isOnFreePlan
          || !this.userPlan.stripeSubscription
          || this.userPlan.stripeSubscription.cancelAtPeriodEnd // @TODO: unless... there's another subscription taking it's place????
        ) return null
        // @TODO: unless... there's another subscription taking it's place????
        return this.payPeriod
      },
      // @TODO: shouldn't this also be 0 if they're paying yearly and the next
      // usage cycle does not coincide with their billing cycle?
      nextUsageCyclePlanCost() {
        if (
          this.isOnFreePlan
          || !this.userPlan.stripePrice
          || !this.userPlan.stripeSubscription
          || this.userPlan.stripeSubscription.cancelAtPeriodEnd // @TODO: unless... there's another subscription taking it's place????
        ) return 0
        return this.userPlan.stripePrice.unitAmount / 100
      },
      estimatedTotal() {
        return this.nextUsageCyclePlanCost + this.ppaCostEstimate
      },
      // @TODO: account for when someone will have PPA charges but not an
      //  upcoming subscription renewal (i.e. awaiting cancellation)
      nextBillingAt() {
        if (this.isOnFreePlan) return 'N/A'
        if (this.isPPAEnabled && this.ppaCostEstimate !== 0) return this.usageCycleEndsAt
        if (this.nextSubscriptionBillingAt) return this.nextSubscriptionBillingAt
        return 'N/A'
      },
    },
  }

</script>

<style lang="stylus" scoped>

  h2
    @apply mb-8

  h4
    @apply mb-6
    @apply text-gray-600

  h5
    @apply mb-2

  section + section
    @apply mt-6

  .columned
    @apply flex
    @apply gap-4
    @apply items-start
    @apply justify-between

  .bordered
    @apply p-8
    @apply border
    @apply rounded
    @apply border-gray-400

  .helper-text
    @apply text-sm
    @apply text-gray-600

  .header
    @apply flex
    @apply gap-6
    @apply flex-col

    +breakpoint(lg)
      @apply flex-row

    h5
      @apply mb-4

    >div
      @apply w-full

  .breakdown
    @apply mt-4

    &.ppa-breakdown
      max-width: 16rem

      // @TODO: remove this?
      // .name
      //   @apply font-bold

    .item
      @apply flex
      @apply gap-2
      @apply justify-between

      &+.item
        @apply mt-2

    .estimated-total
      @apply pt-2
      @apply mt-2
      @apply mb-4

      @apply border-t
      @apply border-gray-400

      @apply text-lg
      @apply font-bold

  .links
    @apply flex
    @apply gap-4
    @apply flex-col
    @apply items-start

    h5
      @apply mb-0

    a
    button
      @apply text-lg
      @apply text-left
      @apply inline-block

  .add-ons
    p + p
      @apply mt-2

  .questions
    @apply p-6
    @apply mt-6
    @apply rounded-md
    @apply text-center
    @apply bg-gray-200

</style>
